































import Vue from 'vue'

export default Vue.extend({
  name: 'Random',

  data () {
    return {
      title: {
        recipe: 'Shake the Magic Salad',
        wine: 'Bottle game time!',
        cocktail: 'Mojito Sensei'
      },
      subtitle: {
        recipe: 'By shaking you will get the recipe',
        wine: 'By shaking you will get the right wine',
        cocktail: 'By shaking you will get the best cocktail'
      },
      showMagicItem: true,
      showResult: false,
      hasMargin: true,
      type: this.$route.path.split('/')[1],
      resultData: []
    }
  },

  methods: {
    hideResult: function () {
      this.showResult = false
      this.showMagicItem = true
      this.hasMargin = true
    },

    setResultData: function (data) {
      var self = this
      this.resultData = (data === null) ? [] : data
      setTimeout(function () {
        self.showResult = true
        self.showMagicItem = false
        self.hasMargin = false
      }, 2500)
    }
  },

  watch: {
    $route () {
      this.type = this.$route.path.split('/')[1]
    }
  },

  metaInfo () {
    return {
      title: 'Random',

      meta: [
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: 'Random' },
        { vmid: 'description', name: 'description', content: 'Gimme Recipe - A recipe generator written javascript' },
        { vmid: 'ogdescription', property: 'og:description', content: 'Gimme Recipe - A recipe generator written javascript' }
      ]
    }
  }
})
